import React from "react"
import styled from "styled-components"

export const UnderConstructionBorder = styled.div`
  border-radius: 5px;
  background: repeating-linear-gradient(
    -40deg,
    hsla(40, 100%, 80%, 0.9),
    hsla(40, 100%, 80%, 0.9) 10px,
    hsla(40, 100%, 60%, 0.9) 10px,
    hsla(40, 100%, 60%, 0.9) 20px
  );
  padding: 0.25em;
  margin-bottom: 2em;
`

export const UnderConstruction = styled.div`
  color: ${({ theme: { colors } }) => colors.primary.lemon["400"]};
  background-color: ${({ theme: { colors } }) => colors.primary.lemon["100"]};
  border-radius: 3px;
  padding: 1em 2em;

  a {
    color: ${({ theme: { colors } }) => colors.primary.lemon["400"]};
    text-decoration-color: ${({ theme: { colors } }) =>
      colors.primary.lemon["300"]};
    transition: all 0.2s ease-in-out;

    :hover,
    :focus {
      color: ${({ theme: { colors } }) => colors.primary.lemon["500"]};
      text-decoration-color: ${({ theme: { colors } }) =>
        colors.primary.lemon["400"]};
    }
    :focus {
      outline: 0;
      border-radius: 2px;
      background-color: ${({ theme: { colors } }) =>
        colors.primary.lemon["200"]};
    }
  }
`

export const UnderConstructionTitle = styled.h3`
  font-family: ${({ theme: { typography } }) =>
    typography.family.smallCaps.sansSerif};
  letter-spacing: 0.05em;
  margin: 0.25em auto;
  text-align: center;
  text-transform: lowercase;
`
