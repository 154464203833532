import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"

// Text Elements
export const H1 = styled.h1`
  color: ${({ theme: { colors } }) => colors.mono["800"]};
  font-weight: 400;
  margin: 0.375em auto 2.25em auto;
  line-height: 1.175;

  @media screen and (max-width: 600px) {
    margin-bottom: 3em;
    font-size: 1.75em;
  }
`

export const H2 = styled.h2`
  color: ${({ theme: { colors } }) => colors.mono["700"]};
  margin-top: 3em;
  margin-bottom: 0.25em;
  font-size: 1.125em;
  font-family: ${({ theme: { typography } }) =>
    typography.family.body.sansSerif};
  font-weight: 600;
`
export const H3 = styled.h3`
  color: ${({ theme: { colors } }) => colors.mono["500"]};
  font-family: ${({ theme: { typography } }) =>
    typography.family.body.sansSerif};
  font-size: 1.125em;
  font-weight: 400;
  margin-top: 2.25em;
  margin-bottom: 0.5em;
`
export const LittleH3 = styled(H3)`
  color: ${({ theme: { colors } }) => colors.mono["500"]};
  border-bottom: thin solid ${({ theme: { colors } }) => colors.mono["300"]};
  font-family: ${({ theme: { typography } }) =>
    typography.family.smallCaps.sansSerif};
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  margin-top: 6em;
  text-transform: lowercase;
`
export const Paragraph = styled.p`
  color: ${({ theme: { colors } }) => colors.mono["800"]};
  font-size: 1rem;
  line-height: 1.425;
  margin-bottom: 1.25em;
`
export const Link = styled(GatsbyLink)`
  color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
  text-decoration-color: ${({ theme: { colors } }) => colors.mono["300"]};
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
    text-decoration-color: ${({ theme: { colors } }) =>
      colors.primary.spruce["300"]};
    outline: 0;
  }

  &:focus {
    outline: 0;
    background-color: ${({ theme: { colors } }) =>
      colors.primary.spruce["100"]};
  }
`

export const CTALink = styled(Link)`
  color: ${({ theme: { colors } }) => colors.mono["800"]};
  text-decoration-color: ${({ theme: { colors } }) => colors.mono["400"]};
  transition: all 0.2s ease-in-out;
  font-weight: 600;

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
    text-decoration-color: ${({ theme: { colors } }) =>
      colors.primary.spruce["300"]};
    outline: 0;
  }

  &:focus {
    outline: 0;
    background-color: ${({ theme: { colors } }) => colors.primary.flame["100"]};
  }
`

export const ExternalLink = styled.a`
  color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
  text-decoration-color: ${({ theme: { colors } }) => colors.mono["300"]};
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
    text-decoration-color: ${({ theme: { colors } }) =>
      colors.primary.spruce["300"]};
    outline: 0;
  }

  &:focus {
    outline: 0;
    background-color: ${({ theme: { colors } }) =>
      colors.primary.spruce["100"]};
  }
`

export const HR = styled.hr`
  border: 0;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.mono["300"]};
  margin: 2em 0;
`

export const Quote = styled.blockquote`
  border-left: 2px solid
    ${({ theme: { colors } }) => colors.primary.pool["300"]};
  padding: 0.25em 1em;
  margin-left: 0;
  font-style: italic;
`

export const Caption = styled.figcaption`
  color: ${({ theme: { colors } }) => colors.mono["400"]};
  font-family: ${({ theme: { typography } }) =>
    typography.family.body.sansSerif};
  font-size: 0.875em;
  margin-top: 1em;
`
