import styled from "styled-components"
import star from "../../assets/icons/star.svg"
import { H2 } from "../global/global.styles"

export const Year = styled(H2)`
  color: ${({ theme: { colors } }) => colors.mono["700"]};
  font-family: ${({ theme: { typography } }) =>
    typography.family.body.sansSerif};
  font-weight: 400;
  font-size: 1em;
  margin-top: 2em;
`

export const List = styled.ul`
  padding-left: 0;
`

export const Item = styled.li`
  align-items: flex-start;
  display: flex;
  list-style: none;
  margin-bottom: 1.25em;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.mono["700"]};
  font-size: 1.125em;

  a {
    color: ${({ theme: { colors } }) => colors.mono["700"]};
    text-decoration-color: ${({ theme: { colors } }) => colors.mono["400"]};
    transition: all 0.2s ease-in-out;

    &:hover {
      color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
      text-decoration-color: ${({ theme: { colors } }) =>
        colors.primary.spruce["300"]};
      outline: 0;
    }

    &:focus {
      outline: 0;
      background-color: ${({ theme: { colors } }) =>
        colors.primary.spruce["100"]};
    }
  }
`
export const Author = styled.span`
  color: ${({ theme: { colors } }) => colors.mono["500"]};
  font-family: ${({ theme: { typography } }) =>
    typography.family.body.sansSerif};
  font-size: 0.875em;
`
export const Star = styled(star)`
  fill: #f1b80d;
  transform: rotateZ(-7deg);
  width: 16px;
  margin-left: -26px;
  margin-right: 8px;
`
