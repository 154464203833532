import React from "react"
import {
  UnderConstructionBorder,
  UnderConstruction,
  UnderConstructionTitle,
} from "./work-services.styles"

export const WorkServices = () => (
  <UnderConstructionBorder>
    <UnderConstruction>
      <UnderConstructionTitle>Under Construction</UnderConstructionTitle>
      <p>
        Details coming soon. Generally, I can help with web development, some
        design work, and marketing strategy. The best way to find out if we’d be
        a good fit is to <a href="mailto:sextonc@me.com">get in touch</a>.
      </p>
    </UnderConstruction>
  </UnderConstructionBorder>
)
