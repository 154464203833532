import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const ProfilePic = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpeg/" }) {
        childImageSharp {
          fixed(width: 120, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Image
      fixed={data.avatar.childImageSharp.fixed}
      style={{
        marginRight: `1em`,
        marginLeft: `0`,
        marginBottom: `1em`,
        minWidth: 50,
        float: `left`,
      }}
      imgStyle={{
        borderRadius: `2px`,
      }}
    />
  )
}

export default ProfilePic
