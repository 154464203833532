import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { List, Post, Excerpt, ReadMoreLink } from "./blog-list.styles"

const BlogList = ({ shouldShowExcerpts }) => {
  const data = useStaticQuery(graphql`
    query Posts {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/posts/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "MMMM D, YYYY")
              slug
              excerpt
            }
          }
        }
      }
    }
  `)
  const posts = data.allMdx.edges.map(edge => {
    const { title, slug, excerpt } = edge.node.frontmatter
    return (
      <Post isCompact={!shouldShowExcerpts}>
        <Link to={slug}>{title}</Link>
        {shouldShowExcerpts && excerpt && (
          <Excerpt>
            {excerpt} <ReadMoreLink to={slug}>Read More</ReadMoreLink>
          </Excerpt>
        )}
      </Post>
    )
  })
  return <List>{posts}</List>
}

export default BlogList
