import styled from "styled-components"
import { Link, Paragraph } from "../global/global.styles"

export const List = styled.ul`
  padding-left: 0;
  list-style: none;
`

export const Post = styled.li`
  color: ${({ theme: { colors } }) => colors.mono["700"]};
  margin-bottom: ${p => (p.isCompact ? "1em" : "2em")};
  font-size: 1.125em;
  & > a {
    color: ${({ theme: { colors } }) => colors.mono["800"]};
    text-decoration-color: ${({ theme: { colors } }) => colors.mono["100"]};
    font-weight: ${p => (p.isCompact ? "400" : "600")};
    transition: all 0.2s ease-in-out;

    &:hover {
      color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
      text-decoration-color: ${({ theme: { colors } }) => colors.mono["300"]};
      outline: 0;
    }

    &:focus {
      outline: 0;
      background-color: ${({ theme: { colors } }) =>
        colors.primary.spruce["100"]};
    }
  }
`

export const Excerpt = styled(Paragraph)`
  color: ${({ theme: { colors } }) => colors.mono["600"]};
  margin-top: 0.25em;
`
export const ReadMoreLink = styled(Link)`
  color: ${({ theme: { colors } }) => colors.primary.spruce["300"]};
  display: inline;
  font-family: ${({ theme: { typography } }) =>
    typography.family.smallCaps.sansSerif};
  text-transform: lowercase;
  font-size: 0.875rem;
`
