// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-blog-mdx": () => import("./../src/pages/blog.mdx" /* webpackChunkName: "component---src-pages-blog-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-reading-mdx": () => import("./../src/pages/reading.mdx" /* webpackChunkName: "component---src-pages-reading-mdx" */),
  "component---src-pages-work-mdx": () => import("./../src/pages/work.mdx" /* webpackChunkName: "component---src-pages-work-mdx" */)
}

