import React from "react"
import books from "../../content/books.yaml"
import { Year, List, Item, Info, Title, Author, Star } from "./booklist.styles"

const reverseSortYears = years => {
  return years.sort((year1, year2) => {
    return parseInt(year2) - parseInt(year1)
  })
}

const generateBookList = books => {
  const bookList = []
  books.forEach((book, index) => {
    const { title, author, starred, link } = book
    bookList.push(
      <Item key={`book-article-${index + 1}`}>
        {starred && <Star />}
        <Info>
          {// Link text if applicable
          link ? (
            <Title>
              <a href={link}>{title}</a>
            </Title>
          ) : (
            <Title>{title}</Title>
          )}
          <Author>{author}</Author>
        </Info>
      </Item>
    )
  })
  return bookList
}

const SubList = ({ year, items }) => {
  const list = generateBookList(items)
  return (
    <>
      <Year>{year}</Year>
      <List>{list}</List>
    </>
  )
}

export const BookList = () => {
  const reverseChronYears = reverseSortYears(Object.keys(books))
  return reverseChronYears.map(year => (
    <SubList year={year} items={books[year]} />
  ))
}
